import React, { Component } from 'react'
import styled from 'styled-components'
import { TweenMax } from 'gsap/TweenMax'
import { common } from '../../utility/common'
import media from '../../utility/media'
import Logo from './Logo'
import SideMenu from './Sidemenu'
import SidebarSocial from './SidebarSocial'

const StyledSidebar = styled.div`
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  position: relative;
  @media (min-width: ${media.md}) {
    width: 85px;
  }
`

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.sidebar = null
    this.sidebarTween = null
    this.logo = null
    this.sideMenu = null
    this.sidebarSocial = null
  }

  componentDidMount() {
    this.sidebarTween = TweenMax.from(this.sidebar, common.duration, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: this.props.delay,
    })
  }

  render() {
    const { hideOverlayCloseMenuHandler, ...rest } = this.props

    return (
      <StyledSidebar innerRef={div => (this.sidebar = div)} onClick={hideOverlayCloseMenuHandler}>
        <Logo {...rest} innerRef={div => (this.logo = div)} />
        <SideMenu {...rest} innerRef={div => (this.sideMenu = div)} />
        <SidebarSocial {...rest} innerRef={div => (this.sidebarSocial = div)} />
      </StyledSidebar>
    )
  }
}

export default Sidebar
