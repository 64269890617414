import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { isSmallScreen } from '../../utility/common'
import theme from '../../utility/theme'
import baseStyles from '../../utility/basestyle'
import Layout from './Layout'

class PageWrapper extends Component {
  state = {
    loadPage: false,
    isSmallScreen: false,
  }

  componentDidMount() {
    this.setState(state => {
      return {
        loadPage: true,
      }
    })

    if (isSmallScreen()) {
      this.setState({ isSmallScreen: true })
    }
  }

  resetScroll = () => {
    // change scroll top
    this._scrollRef.scrollTop = 0

    // change scroll left
    this._scrollRef.scrollLeft = 0
  }

  render() {
    const { children, ...rest } = this.props
    const { loadPage, isSmallScreen } = this.state

    const finalLayout = !isSmallScreen ? (
      <PerfectScrollbar containerRef={div => (this._scrollRef = div)}>
        <Layout {...rest} resetScrollHandler={this.resetScroll}>{children}</Layout>
      </PerfectScrollbar>
    ) : (
      <Layout {...rest}>{children}</Layout>
    )

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'I am Abdus Salam, a Freelance Web Designer & Front-end Developer specializing in user-centered UI/UX Design & WordPress Development.' },
              ]}
            >
              <html lang="en" />
              <link
                href="https://fonts.googleapis.com/css?family=Abril+Fatface|Montserrat:300,400,500,700"
                rel="stylesheet"
              />
              <style type="text/css">{`
                  html,body {
                      background-color: ${theme.colorBg} !important;
                      margin: 0;
                      padding: 0;
                  }
              `}</style>
            </Helmet>

            {baseStyles()}

            {loadPage ? finalLayout : null}
          </>
        )}
      />
    )
  }
}

export default PageWrapper
