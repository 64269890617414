import React, { Component } from 'react'
import styled from 'styled-components'
import media from '../../utility/media'
import { Location } from '@reach/router'
import LogoImg from '../../images/logo.png'

const StyledLogoImg = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: ${media.md}) {
    width: 80%;
  }
`

const StyledLink = styled.a`
  text-align: center;
`

class Logo extends Component {
  clicked = (e, currentPageLocation, nextPageLocation) => {
    if (currentPageLocation === nextPageLocation) {
      e.preventDefault()
      return
    }

    //Pass handller back to Layout.js for page transition function
    this.props.startPageChangingHandler(nextPageLocation)

    e.preventDefault()
  }

  render() {
    return (
      <Location>
        {({ location }) => {
          const currentPath = location.pathname

          return (
            <StyledLink
              href="/"
              onClick={e => this.clicked(e, currentPath, '/')}
            >
              <StyledLogoImg src={LogoImg} alt="logo" />
            </StyledLink>
          )
        }}
      </Location>
    )
  }
}

export default Logo
