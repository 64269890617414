import React, { Component } from 'react'
import styled from 'styled-components'
import { TweenMax, TimelineMax, Power0 } from 'gsap/TweenMax'
import { navigate } from 'gatsby'
import { common } from '../../utility/common'
import { isSmallScreen } from '../../utility/common'
import media from '../../utility/media'
import theme from '../../utility/theme'
import Sidebar from '../sidenav/Sidebar'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.9;
  background-color: ${theme.colorBg};
`
const LayoutInner = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100vh;
  @media (min-width: ${media.md}) {
    width: 720px;
  }
  @media (min-width: ${media.lg}) {
    width: 922px;
  }
  @media (min-width: ${media.xl}) {
    width: 1200px;
  }
`

//Bas estyling for SidebarContainer and LayoutContainer
const LayoutContainerBase = styled.div`
  min-height: 100%;
  padding-top: 20px;
  padding-bottom: 40px;
  @media (min-width: ${media.md}) {
    padding-top: 80px;
    padding-bottom: 100px;
  }
  @media (min-width: ${media.lg}) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`

const LayoutSidenav = styled(LayoutContainerBase)`
  position: fixed;
  z-index: 100;
  min-height: 100%;
  display: flex;
  ::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 2000px;
    background-color: #13151b;
    z-index: 1;
  }
`

const LayoutContainer = styled(LayoutContainerBase)`
  width: 100%;
  min-height: 100%;
  padding-left: 85px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${media.md}) {
    padding-left: 130px;
  }

  @media (min-width: ${media.lg}) {
    padding-left: 160px;
  }
`

const PageChanger = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: ${theme.colorPrimary};
`

class Layout extends Component {
  state = {
    pageChanging: false,
    contentLoader: false,
    overlay: false,
    showMobileMenu: false,
    showMobileSocialMenu: false,
  }

  constructor(props) {
    super(props)
    this.sideNav = null
    this.sideNavTween = null
    this.layoutContainer = null
    this.layoutContainerTween = null
    this.pageChanger = null
    this.pageChangerTween = null
  }

  componentDidMount() {
    this.sideNavTween = TweenMax.from(this.sideNav, common.timingOffset, {
      transform: 'translateX(-115vw)',
      ease: common.easing.easeOut,
    })

    this.layoutContainerTween = TweenMax.from(
      this.layoutContainer,
      common.duration,
      {
        autoAlpha: 0,
        ease: common.easing.easeOut,
        delay: common.timingOffset,
      }
    )
  }

  componentDidUpdate(prevState) {
    if (prevState.pageChanging !== this.state.pageChanging) {
      if (
        this.state.pageChanging === true &&
        this.state.contentLoader === false
      ) {
        this.pageChangerTween = new TimelineMax()

        this.pageChangerTween.add(
          TweenMax.from(this.pageChanger, 0.3, {
            transform: 'translateX(125vw)',
            ease: common.easing.easeIn,
          })
        )

        this.pageChangerTween.add(
          TweenMax.to(this.pageChanger, 0.4, { x: 0, ease: Power0.easeNone })
        )

        this.pageChangerTween.add(
          TweenMax.to(this.pageChanger, 0.2, {
            transform: 'translateX(-100vw)',
            ease: Power0.easeNone,
            onComplete: () => this.stopPageChanging(),
          })
        )
      }
    }
  }

  /**
   *  To make page transition effect
   *  args: nextPagePath
   */
  startPageChanging = currentPagePath => {
    this.setState(prevState => {
      return {
        pageChanging: true,
        currentPagePath: currentPagePath,
      }
    })

    //Place loader for nextPage content after some time
    this.timerContentLoader = setTimeout(() => {
      this.setState({
        contentLoader: true,
      })
    }, 50)
  }

  /**
   * Navigate to the `nextPagePath` after page transition
   */
  stopPageChanging = () => {

    navigate(this.state.currentPagePath)

    // reset scroll position
    if(!isSmallScreen()){
      this.props.resetScrollHandler()
    }

    this.setState({
      pageChanging: false,
      contentLoader: false,
    })

  }

  toggleMobileMenu = () => {
    this.setState(state => {
      return {
        showMobileMenu: !state.showMobileMenu,
        overlay: !state.overlay,
      }
    })
  }

  toggleMobileSocialMenu = () => {
    this.setState(state => {
      return {
        showMobileSocialMenu: !state.showMobileSocialMenu,
        overlay: !state.overlay,
      }
    })
  }

  hideOverlayCloseMenu = e => {
    e.preventDefault()
    if (e.target === e.currentTarget) {
      this.setState(state => {
        return {
          showMobileSocialMenu: false,
          showMobileMenu: false,
          overlay: false,
        }
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerContentLoader)
  }

  render() {
    const { children, isFirstLoad, ...rest } = this.props
    const {
      pageChanging,
      contentLoader,
      overlay,
      showMobileMenu,
      showMobileSocialMenu,
    } = this.state

    const pageChildren = React.cloneElement(children, {
      isFirstLoad: isFirstLoad,
      timingOffset: isFirstLoad ? common.timingOffset + 0.3 : 0.3,
      startPageChangingHandler: this.startPageChanging,
    })

    return (
      <div className="layout-wrapper">
        {pageChanging ? (
          <PageChanger innerRef={div => (this.pageChanger = div)} />
        ) : null}
        {overlay ? (
          <Overlay onClick={e => this.hideOverlayCloseMenu(e)} />
        ) : null}
        <LayoutInner>
          <LayoutSidenav
            innerRef={div => (this.sideNav = div)}
            onClick={e => this.hideOverlayCloseMenu(e)}
          >
            <Sidebar
              isFirstLoad={isFirstLoad}
              delay={common.timingOffset}
              startPageChangingHandler={this.startPageChanging}
              toggleMobileMenuHandler={this.toggleMobileMenu}
              toggleMobileSocialMenuHandler={this.toggleMobileSocialMenu}
              showMobileMenu={showMobileMenu}
              showMobileSocialMenu={showMobileSocialMenu}
              hideOverlayCloseMenuHandler={e => this.hideOverlayCloseMenu(e)}
              {...rest}
            />
          </LayoutSidenav>

          <LayoutContainer
            innerRef={div => (this.layoutContainer = div)}
            isFirstLoad={isFirstLoad}
          >
            {contentLoader ? null : pageChildren}
          </LayoutContainer>
        </LayoutInner>
      </div>
    )
  }
}

export default Layout
