import React, { Component } from 'react'
import styled from 'styled-components'
import media from '../../utility/media'
import theme from '../../utility/theme'
import { isMenuClickable } from '../../utility/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGithub,
  faDribbble,
  faBehance,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'

/* Side Social Menu */
const StyledSidebarSocial = styled.div`
  width: 45%;
  @media (max-width: ${media.md}) {
    width: 55%;
  }
`

const SidebaSocialMenu = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 15;
  transition: opacity 300ms ease-out;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`

const ListItem = styled.li`
  padding-top: 7px;
  padding-bottom: 7px;
`

const LinkItem = styled.a``

const StyledFa = styled(FontAwesomeIcon)`
  color: ${theme.colorSecondary};
  font-size: 16px;
  ${LinkItem}:hover & {
    color: #fff;
  }
`

const MenuButton = styled.div`
  height: 41px;
  cursor: pointer;
`

const IconItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const IconItem = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${theme.colorSecondary};
  visibility: ${({ isVisible }) => (isVisible ? 'hidden' : 'visible')};
  opacity: ${({ isVisible }) => (isVisible ? 0 : 1)};
`

const FollowContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const Follow = styled.div`
  color: #fff;
  font-size: 8px;
  font-weight: 500;
  text-transform: uppercase;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`

class SidebarSocial extends Component {
  state = {
    isVisible: false,
  }

  componentDidUpdate(prevProps) {
    if (isMenuClickable()) {
      if (prevProps.showMobileSocialMenu !== this.props.showMobileSocialMenu) {
        this.setState({
          isVisible: this.props.showMobileSocialMenu,
        })
      }
    }
  }

  showSocialMenuOnOnMouseEnter = () => {
    if (isMenuClickable()) {
      return
    }

    this.timeMenuShow = setTimeout(() => {
      this.setState({
        isVisible: true,
      })
    }, 1)
  }

  showSocialMenuOnClick = () => {
    if (isMenuClickable()) {
      this.setState({
        isVisible: true,
      })
      this.props.toggleMobileSocialMenuHandler()
    }
  }

  hideSocialMenuOnMouseLeave = () => {
    if (isMenuClickable()) {
      return
    }
    this.timeMenuHide = setTimeout(() => {
      this.setState({
        isVisible: false,
      })
    }, 1)
  }

  componentWillUnmount() {
    clearTimeout(this.timeMenuShow)
    clearTimeout(this.timeMenuHide)
  }

  render() {
    const { isVisible } = this.state

    return (
      <StyledSidebarSocial onMouseLeave={this.hideSocialMenuOnMouseLeave}>
        <SidebaSocialMenu isVisible={isVisible}>
          <ListItem>
            <LinkItem href="https://github.com" target="_blank">
              <StyledFa icon={faGithub} />
            </LinkItem>
          </ListItem>
          <ListItem>
            <LinkItem href="https://dribble.com" target="_blank">
              <StyledFa icon={faDribbble} />
            </LinkItem>
          </ListItem>
          <ListItem>
            <LinkItem href="https://behance.com" target="_blank">
              <StyledFa icon={faBehance} />
            </LinkItem>
          </ListItem>
          <ListItem>
            <LinkItem href="https://twitter.com" target="_blank">
              <StyledFa icon={faTwitter} />
            </LinkItem>
          </ListItem>
          <ListItem>
            <LinkItem href="https://linkedin.com" target="_blank">
              <StyledFa icon={faLinkedin} />
            </LinkItem>
          </ListItem>
        </SidebaSocialMenu>
        <MenuButton
          onMouseEnter={this.showSocialMenuOnOnMouseEnter}
          onClick={this.showSocialMenuOnClick}
        >
          {isVisible ? (
            <FollowContainer>
              <Follow isVisible={isVisible}>Follow</Follow>
            </FollowContainer>
          ) : (
            <IconItemContainer isVisible={isVisible}>
              <IconItem />
              <IconItem />
              <IconItem />
            </IconItemContainer>
          )}
        </MenuButton>
      </StyledSidebarSocial>
    )
  }
}

export default SidebarSocial
