// Screen sizes for various media
const theme = {
  fontFamily: 'Montserrat, sans-serif',
  fontTitle: '"Abril Fatface", cursive',
  colorPrimary: '#e44026',
  colorSecondary: '#8f92a0',
  colorSuccess: '#4e9525',
  colorBg: '#292c39',
  colorGrey: '#383c4f',
  colorBlog: '#C1C4D0',
}

export default theme