/**
 *****************************************
 * Common variables across the application
 * ***************************************
 */

import { Power2 } from 'gsap/TweenMax'

export const common = {
  //timingOffset for initial sidebar transition duration in seconds
  timingOffset: 0.5,
  //delay for animation duration in seconds
  duration: 0.3,
  easing: Power2
}

/**
 ******************************************
 * Common functions across the applications
 * ****************************************
 */

export const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(
    String(email)
      .toLowerCase()
      .trim()
  )
}

export const countCharacter = (string, chr) => {
  var patt = new RegExp(chr, 'g')
  return (string.match(patt) || []).length
}

export const isSmallScreen = () => {
  var width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  )
  return width < 768
}

export const isBigScreen = () => {
  var width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  )
  return width > 1024
}

export const isMenuClickable = () => {
  var width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  )
  return width <= 1024
}
