import React, { Component } from 'react'
import styled from 'styled-components'
import { Location } from '@reach/router'
import theme from '../../utility/theme'
import media from '../../utility/media'
import { countCharacter, isMenuClickable } from '../../utility/common'

const StyledSideMenu = styled.ul`
  width: 50px;
  margin: 0;
  margin-left: 0px;
  padding-left: 24px;
  list-style: none;
  overflow: hidden;
  position: absolute;
  z-index:10;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (min-width: ${media.md}) {
    width: 80px;
    padding-left: 40px;
  }
  &:hover {
    overflow: visible;
  }
`
const ListItem = styled.li`
  position: relative;
  width: 130px;
  z-index: 10;
`
const BarItem = styled.span`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  width: 2px;
  height: 25px;
  background-color: ${theme.colorGrey};
  &.active {
    background-color: ${theme.colorPrimary};
  }
`

const LinkItem = styled.a`
  font-size: 12px;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 500;
  color: ${theme.colorSecondary};
  line-height: 2;
  text-transform: uppercase;
  padding-left: 18px;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms linear
    ${isMenuClickable => (isMenuClickable ? null : '300ms')};

  &::before {
    content: '';
    position: absolute;
    left: -15%;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    display: inline-block;
    height: 25px;
  }

  &:hover {
    color: #fff;
    @media (max-width: ${media.xl}) {
      color: ${theme.colorSecondary};
    }
  }

  &:focus {
    -webkit-tap-highlight-color: ${isMenuClickable =>
      isMenuClickable ? 'rgba(0, 0, 0, 0);' : 'initial'};
  }

  ${StyledSideMenu}:hover & {
    visibility: visible;
    opacity: 1;
    @media (max-width: ${media.xl}) {
      visibility: 'hidden';
      opacity: 0;
    }
  }
`

const menuItems = [
  {
    path: '/',
    label: 'About Me',
  },
  {
    path: '/portfolio/',
    label: 'My Works',
  },
  {
    path: '/blog/',
    label: 'Read Me',
  },
  {
    path: '/contact/',
    label: 'Contact Me',
  },
]

class SideMenu extends Component {
  state = {
    hover: true,
    isMenuClickable: false,
  }

  componentDidMount() {
    if (isMenuClickable()) {
      this.setState({ isMenuClickable: true })
    }
  }

  clicked = (e, currentPageLocation, nextPageLocation) => {
    // For small screen call clickedMobile
    if (this.state.isMenuClickable) {
      e.preventDefault()
      this.clickedMobile(e, currentPageLocation, nextPageLocation)
      return
    }

    //Not mobile
    if (currentPageLocation === nextPageLocation) {
      e.preventDefault()
      return
    }

    this.setState(prevState => ({ hover: false }))

    //Pass handller back to Layout.js for page transition function
    this.props.startPageChangingHandler(nextPageLocation)

    this.timerHideLink = setTimeout(() => {
      this.setState(prevState => ({ hover: true }))
    }, 1000)
    e.preventDefault()
  }

  clickedMobile = (e, currentPageLocation, nextPageLocation) => {
    if (this.props.showMobileMenu) {
      if (currentPageLocation === nextPageLocation) {
        e.preventDefault()
        return
      }

      this.setState(prevState => ({ hover: false }))

      //Pass handller back to Layout.js for page transition function
      this.props.startPageChangingHandler(nextPageLocation)

      this.timerHideLink = setTimeout(() => {
        this.setState(prevState => ({ hover: true }))
      }, 1000)

      e.preventDefault()

      this.props.toggleMobileMenuHandler()
    } else {
      this.props.toggleMobileMenuHandler()
    }
  }

  isActivePath = (currentPath, nextPath) => {
    // If 1 "/" it is index page else other page
    if (countCharacter(nextPath, '/') <= 1) {
      return currentPath === '/'
    } else {
      return currentPath.includes(nextPath)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerHideLink)
  }

  render() {
    const { hover } = this.state
    const { showMobileMenu } = this.props

    return (
      <Location>
        {({ location }) => {
          const currentPath = location.pathname

          return (
            <StyledSideMenu
              style={{
                pointerEvents: hover ? 'auto' : 'none',
                overflow: showMobileMenu ? 'initial' : null,
              }}
            >
              {menuItems.map(({ path, label }) => {
                return (
                  <ListItem key={path}>
                    <BarItem
                      className={
                        this.isActivePath(currentPath, path) ? 'active' : null
                      }
                    />
                    <LinkItem
                      isMenuClickable={this.state.isMenuClickable}
                      href={path}
                      style={
                        showMobileMenu
                          ? {
                              visibility: 'visible',
                              opacity: 1,
                            }
                          : null
                      }
                      onClick={e => this.clicked(e, currentPath, path)}
                    >
                      {label}
                    </LinkItem>
                  </ListItem>
                )
              })}
            </StyledSideMenu>
          )
        }}
      </Location>
    )
  }
}

export default SideMenu
